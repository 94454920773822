.view-profile-custom-card .ant-card-head {
  background-color: #12a1a3;
  overflow-y: auto;
}

form#editProfileDetailForm {
  /* border: 1px solid #12a1a3; */
  border-radius: 0px;
  margin: 0px 6px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 25px !important;
}


.myWidth {
  height: 168px;
  width: 778px;
}

@media screen and (max-width: 768px) {
  .myWidth {
    height: 150px;
    width: 380px;
  }
}

