
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  font-family: Roboto;
}

/* New CSS */

@media (max-width: 768px) {
  .textsize {
    font-size: 16px;
  }
  .logoimg {
    width: 150px !important;
  }
  .LogoImg2 {
    width: 150px;
  }
  .bgContainer {
    height: 40%;
  }
  .textContainer {
    height: 60%;
  }
  .inputFieldGroup {
    width: 90% !important;
    margin-left: 18px;
  }
}
@media (max-width: 576px) {
  .wrap-login100 {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.mainContainer {
  overflow: hidden;
}
.btnGroup {
  align-self: center;
  width: 70%;
}
.btn-forget-pass {
  background: #12a1a3;
  color: white;
  /* border-radius: 18px; */
  padding: 3px;
  border: none;
  line-height: 2;
}

.bgImg {
  object-fit: cover;
  filter: brightness(0.5);
  width: 100%;
  height: 100%;
}
.logoimg {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 200px;
  z-index: 1;
}
.cardWidth {
  width: 400px;
}
.LogoImgSize {
  width: 200px;
}
