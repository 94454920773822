.viewCardsContainer {
  min-height: 60vh;
  overflow-y: auto;
}
.cards-search-button .ant-btn-primary {
  background-color: #12A1A3; /* Change to desired color */
  border-color: #12A1A3; /* Change to desired color */
}
.cards-search-button .ant-btn-primary:hover {
  background-color: #12A1A3 !important; /* Hover color */
  border-color: #12A1A3 !important;
}

.edit-card-modal-form {
  border: 1px solid #12a1a3;
  border-radius: 0px;
  margin: 0px 6px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 25px !important;

  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;



}
