form#editCompanyDetailForm {
  /* border: 1px solid #12a1a3; */
  border-radius: 0px;
  margin: 0px 6px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 25px !important;
}

.editCompanySocialMediaForm {
  max-height: 60vh;
  overflow-y: auto;

  /* border: 1px solid #12a1a3; */
  border-radius: 0px;
  margin: 0px 6px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 25px !important;
  /* Modern Scrollbar */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #12a1a3 #f1f1f1; /* Thumb and track colors */
}

.editCompanySocialMediaForm::-webkit-scrollbar {
  width: 8px; /* Scrollbar width */
}

.editCompanySocialMediaForm::-webkit-scrollbar-thumb {
  background: #12a1a3; /* Scrollbar thumb color */
  border-radius: 4px; /* Rounded corners for thumb */
}

.editCompanySocialMediaForm::-webkit-scrollbar-thumb:hover {
  background: #0e8283; /* Darker color on hover */
}

.editCompanySocialMediaForm::-webkit-scrollbar-track {
  background: #f1f1f1; /* Scrollbar track color */
}

@media screen and (max-width: 768px) {
  .companyDetails_Row1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.image-container {
  display: inline-block;
  width: 100%; /* Adjust width as needed */
  max-width: 200px; /* Limit size of the avatar */
  height: auto; /* Allow flexible height */
}

.avatar-contain {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensures the image is fully visible */
  border-radius: 4px; /* Adjust as needed to maintain Avatar styling */
}
