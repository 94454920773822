form#AddBranchForm {
  /* border: 1px solid #12a1a3; */
  border-radius: 0px;
  margin: 0px 6px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 25px !important;
}

.editCompanySocialMediaForm {
  max-height: 60vh;
  overflow-y: auto;

  /* border: 1px solid #12a1a3; */
  border-radius: 0px;
  margin: 0px 6px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 25px !important;
}

.ant-upload.ant-upload-select {
  width: 100%;
}
