* {
  font-family: "Roboto" !important;
  margin: 0px;
  padding: 0px;
}

body {
  margin: 0;
  font-family: "Roboto" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-card-head {
  background-color: #12a1a3 !important;
}
