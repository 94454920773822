* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto" !important;
}

body p {
  font-family: "Roboto" !important;
}

/* list scroll  */
.list {
  -ms-overflow-style: none;
}
.list::-webkit-scrollbar {
  display: none;
}

/* html text */
.html_text_render {
  font-size: small;
  color: black;
}

.html_text_render img {
  height: auto;
  width: 100%;
}

/* html link  */
.html_link {
  color: gray;
  text-decoration: none;
  text-wrap: "none";
}

.html_link:hover {
  color: gray;
  text-decoration: underline;
}

.no-scrollbar {
  overflow-x: scroll; /* Enable horizontal scrolling */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Hides scrollbar in WebKit-based browsers */
}

.no-scrollbar {
  -ms-overflow-style: none; /* Hides scrollbar in IE and Edge */
  scrollbar-width: none; /* Hides scrollbar in Firefox */
}
